import * as React from 'react';
import styled from 'styled-components';
import { BaseContainer } from '../baseComponents';
import { usePerson } from '../../hooks/data/usePerson';
import { colors, fonts, mq } from '../../styles/theme';
import SocialLinks from '../SocialLinks';

interface IFooterProps {
  socialLabel: string;
}

const Footer: React.FC<IFooterProps> = ({ socialLabel }) => {
  const personInfo = usePerson();
  return (
    <StyledFooter>
      <Container>
        {personInfo?.name} © {new Date().getFullYear()}
        <SocialContainer>
          <span>{socialLabel}</span>
          <StyledSocialLinks />
        </SocialContainer>
      </Container>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  border-top: 1px solid ${colors.passiveGrey};
`;

const Container = styled(BaseContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${fonts.sansSerif};
  padding-top: 2rem !important;
  padding-bottom: 3rem !important;
  flex-direction: column;
  ${mq.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 1rem;
  span {
    margin-bottom: 0.25rem;
    ${mq.tablet} {
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
  ${mq.tablet} {
    flex-direction: row;
    margin-top: 0;
  }
`;

const StyledSocialLinks = styled(SocialLinks)`
  display: flex;
  align-items: center;
  ${mq.tablet} {
    margin-right: 1rem;
  }
  a {
    line-height: 0;
    &:not(:last-of-type) {
      margin-right: 1rem;
    }
  }
  svg {
    height: 24px;
  }
`;

export default React.memo(Footer);
