import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import useLocaleData from '../../hooks/data/useLocaleData';
import { updateUrlLocale } from '../../utils/i18n';
import { colors, fonts } from '../../styles/theme';

interface ILocaleToggle {
  locale: string;
  pagePathName: string;
  className?: string;
}

const LocaleToggle: React.FC<ILocaleToggle> = ({
  locale,
  pagePathName,
  className,
}) => {
  const localeData = useLocaleData();
  return (
    <Container className={className}>
      {localeData?.languages?.map((language) => {
        const languageLocale = language as string;
        return (
          <Link
            key={languageLocale}
            to={updateUrlLocale(locale, languageLocale, pagePathName)}
            className={locale === languageLocale ? 'active' : ''}
          >
            {languageLocale.toUpperCase()}
          </Link>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  background-color: ${colors.surface};
  a {
    font-family: ${fonts.sansSerif};
    font-size: 16px;
    line-height: 16px;
    color: inherit;
    text-decoration: none;
    font-weight: bold;
    &:first-of-type {
      padding-right: 0.25rem;
      margin-right: 0.25rem;
      border-right: 1px solid ${colors.text.darkGrey};
    }
    &:not(.active) {
      color: ${colors.text.grey};
      font-weight: inherit;
    }
  }
`;

export default LocaleToggle;
