import * as React from 'react';
import { ReactElement } from 'react';
import { usePerson } from '../../hooks/data/usePerson';
import { Github, Linkedin, Instagram } from '../../icons';

const enum SocialLink {
  github = 'github',
  instagram = 'instagram',
  linkedin = 'linkedin',
}

type Data = {
  [x in SocialLink]: {
    icon: ReactElement;
    name: string;
  };
};

type ISocialLinksProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const SocialLinks: React.FC<ISocialLinksProps> = (props) => {
  const personInfo = usePerson();
  const getSocialName = (url: string): SocialLink => {
    let name = SocialLink.github;
    if (url.includes(SocialLink.github)) name = SocialLink.github;
    if (url.includes(SocialLink.instagram)) name = SocialLink.instagram;
    if (url.includes(SocialLink.linkedin)) name = SocialLink.linkedin;
    return name;
  };

  const data: Data = {
    github: {
      icon: <Github />,
      name: 'Github',
    },
    instagram: {
      icon: <Instagram />,
      name: 'Instagram',
    },
    linkedin: {
      icon: <Linkedin />,
      name: 'Linkedin',
    },
  };
  return (
    <div {...props}>
      {personInfo &&
        (personInfo.social ? personInfo.social : []).map((url) => {
          const _url = String(url);
          return (
            <a
              key={_url}
              href={_url}
              title={data[getSocialName(_url)].name}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data[getSocialName(_url)].icon}
            </a>
          );
        })}
    </div>
  );
};

export default React.memo(SocialLinks);
