import * as React from 'react';
import styled from 'styled-components';
import { BaseContainer } from '../baseComponents';
import { mq } from '../../styles/theme';
import {
  ContentfulNavigationItem,
  NavigationInfoFragment,
} from '../../../gatsby-graphql';
import HeaderNav from './HeaderNav';
import MobileHeaderNav from './MobileHeaderNav';

interface IHeaderProps {
  section?: Pick<ContentfulNavigationItem, 'name' | 'slug'> | null;
  siteNavigation?: NavigationInfoFragment | null;
  locale: string;
  pagePathName: string;
}

const Header: React.FC<IHeaderProps> = ({
  section,
  siteNavigation,
  locale,
  pagePathName,
}) => (
  <StyledHeader>
    <HeaderInner>
      <MobileHeaderNav {...{ siteNavigation, section, locale, pagePathName }} />
      <HeaderNav {...{ siteNavigation, locale, pagePathName }} />
    </HeaderInner>
  </StyledHeader>
);

const StyledHeader = styled.header`
  ${mq.tablet} {
    z-index: 1;
    position: relative;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
  }
`;

const HeaderInner = styled(BaseContainer)`
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: white;
`;

export default Header;
