import { useLayoutEffect, useRef, useState, RefObject } from 'react';

interface IElementDimensions {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
  x: number;
  y: number;
}

const initialDimensions: IElementDimensions = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0,
};

const useGetElementClientRect = (): [
  RefObject<HTMLElement>,
  IElementDimensions
] => {
  const [domRect, setDomRect] = useState<IElementDimensions>(initialDimensions);
  const elementRef = useRef<HTMLElement>(null);

  useLayoutEffect(() => {
    if (elementRef && elementRef.current) {
      const clientRect = elementRef.current.getBoundingClientRect();
      setDomRect(clientRect.toJSON());
    }
  }, []);
  return [elementRef, domRect];
};

export default useGetElementClientRect;
