import { graphql, useStaticQuery } from 'gatsby';
import { LocaleDataQuery } from '../../../gatsby-graphql';

const useLocaleData = () => {
  const { site } = useStaticQuery<LocaleDataQuery>(graphql`
    query LocaleData {
      site {
        siteMetadata {
          languages
          defaultLang
        }
      }
    }
  `);
  return site?.siteMetadata;
};

export default useLocaleData;
