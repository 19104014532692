import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import useGetElementClientRect from '../../hooks/useGetElementClientRect';
import useHandleScroll from '../../hooks/useHandleScroll';
import { Logo, Menu, Close } from '../../icons';
import { borders, colors, fonts, mq } from '../../styles/theme';
import SocialLinks from '../SocialLinks';
import {
  ContentfulNavigationItem,
  SiteNavigationQuery,
} from '../../../gatsby-graphql';
import { getBrowserLocaleFromURL } from '../../utils/i18n';
import LocaleToggle from '../LocaleToggle';
import { _window, isBuildTime } from '../../utils';

interface IMobileHeaderNavProps {
  siteNavigation: SiteNavigationQuery['contentfulNavigation'];
  section?: Pick<ContentfulNavigationItem, 'name' | 'slug'> | null;
  locale: string;
  pagePathName: string;
}

const MobileHeaderNav: React.FC<IMobileHeaderNavProps> = ({
  siteNavigation,
  section,
  locale,
  pagePathName,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [navRef, navClientRect] = useGetElementClientRect();
  const { enableWindowScroll, disableWindowScroll } = useHandleScroll();
  const [props, set] = useSpring(() => ({
    top: isBuildTime ? -1080 : -window.outerHeight,
  }));

  useEffect(() => {
    set({ top: isMenuOpen ? navClientRect.height : -window.outerHeight });
    if (isMenuOpen) {
      disableWindowScroll();
    } else {
      enableWindowScroll();
    }
  }, [isMenuOpen, disableWindowScroll, enableWindowScroll]);

  return (
    <MobileHeader>
      <Spacer style={{ height: `${navClientRect.height}px` }} />
      <StyledNav ref={navRef}>
        <LeftContainer>
          <LogoLink to={`/${getBrowserLocaleFromURL()}`} title="Home">
            <StyledLogo />
          </LogoLink>
          {section && (
            <SectionLink to={String(section.slug)}>{section.name}</SectionLink>
          )}
        </LeftContainer>
        <MenuButton onClick={() => setIsMenuOpen((prevState) => !prevState)}>
          {isMenuOpen ? <Close /> : <Menu />}
        </MenuButton>
      </StyledNav>
      <SliderMenu
        style={props}
        height={`calc(${_window.innerHeight}px - ${navClientRect.height}px)`}
      >
        <LinksContainer>
          {siteNavigation?.navigation?.map(
            (item) =>
              item && (
                <StyledLink
                  key={String(item.link)}
                  to={`/${getBrowserLocaleFromURL()}${item.link}`}
                  title={String(item.name)}
                >
                  {item.name}
                </StyledLink>
              )
          )}
        </LinksContainer>
        <div>
          <StyledSocialLinks />
          <LocaleToggle {...{ locale, pagePathName }} />
        </div>
      </SliderMenu>
    </MobileHeader>
  );
};

const Spacer = styled.div`
  ${mq.tablet} {
    visibility: hidden;
    position: absolute;
    top: -100px;
  }
`;

const MobileHeader = styled.div`
  ${mq.tablet} {
    visibility: hidden;
  }
`;

const StyledNav = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  background: ${colors.surface};
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LogoLink = styled(Link)`
  line-height: 0;
`;

const StyledLogo = styled(Logo)`
  height: 26px;
`;

const SectionLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  font-family: ${fonts.sansSerif};
  font-weight: bold;
  margin-left: 0.75rem;
  padding-left: 0.5rem;
  font-size: 1.25rem;
  ${borders.verticalLeftSpacer};
`;

const MenuButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  line-height: 0;
  svg {
    height: 26px;
  }
`;

const SliderMenu = styled(animated.div)<{ height: string }>`
  position: absolute;
  left: 0;
  z-index: 1;
  height: ${({ height }) => height};
  width: 100%;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${colors.surface};
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLink = styled(Link)`
  font-family: ${fonts.sansSerif};
  font-size: 2rem;
  line-height: 2rem;
  font-weight: bold;
  text-decoration: none;
  color: inherit;
  &:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }
  ${mq.normalMobile} {
    font-size: 3rem;
    line-height: 3rem;
  }
`;

const StyledSocialLinks = styled(SocialLinks)`
  margin: 4rem 0 2rem;
  a {
    &:not(:last-of-type) {
      margin-right: 1rem;
    }
  }
  svg {
    height: 28px;
    ${mq.normalMobile} {
      height: 32px;
    }
  }
`;

export default React.memo(MobileHeaderNav);
