import { css } from 'styled-components';

export const fonts = {
  serif: 'Georgia, Cambria, "Times New Roman", Times, serif',
  sansSerif:
    'Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif',
  code:
    '"SFMono-Regular", Consolas, "Roboto Mono", "Droid Sans Mono", "Liberation Mono", Menlo, Courier, monospace;',
};

export const colors = {
  surface: '#FFF',
  passiveGrey: 'rgba(0,0,0,.1)',
  text: {
    darkGrey: 'rgba(0,0,0,.76)',
    grey: 'rgba(0,0,0,.54)',
  },
  border: {
    grey: 'rgba(0,0,0,.15)',
  },
};

export const widthBreakPoints = {
  normalMobile: 340,
  tablet: 768,
  desktop: 1024,
  desktopWide: 1440,
};

export const mq = {
  normalMobile: `@media (min-width: ${widthBreakPoints.normalMobile}px)`,
  tablet: `@media (min-width: ${widthBreakPoints.tablet}px)`,
  desktop: `@media (min-width: ${widthBreakPoints.desktop}px)`,
  untilTablet: `@media (max-width: ${widthBreakPoints.tablet}px)`,
};

export const borders = {
  blogPostImageBorder: `border: 1px solid ${colors.border.grey}`,
  spacer: `border-bottom: 1px solid ${colors.border.grey}`,
  verticalLeftSpacer: `border-left: 1px solid ${colors.border.grey}`,
  radius: 'border-radius: 4px',
};

export const spacing = {
  sectionPageContent: {
    top: {
      base: '2rem',
      desktop: '3rem',
    },
  },
  grid: {
    column: {
      gap: '24px',
    },
    row: {
      gap: '45px',
    },
  },
};

export const DottedBackground = css`
  background: linear-gradient(90deg, ${colors.surface} 50px, transparent 1%)
      center,
    linear-gradient(${colors.surface} 50px, transparent 1%) center, #000;
  background-size: 52px 52px;
`;
