import * as React from 'react';
import styled from 'styled-components';
import Seo from '../Seo';
import Header from '../Header';
import Footer from '../Footer';
import GlobalStyle from '../../styles/GlobalStyles';
import { colors } from '../../styles/theme';
import {
  ContentfulNavigationItem,
  NavigationInfoFragment,
} from '../../../gatsby-graphql';

interface ILayoutProps {
  children: React.ReactNode;
  section?: Pick<ContentfulNavigationItem, 'name' | 'slug'> | null;
  siteNavigation?: NavigationInfoFragment | null;
  pageTitle?: string;
  pageDescription?: string;
  pageImage?: string;
  pagePathName: string;
  lang?: string;
  withHeader?: boolean;
  withFooter?: boolean;
  withBottomSpacing?: boolean;
  footerSocialLabel: string;
  className?: string;
}

const Layout: React.FC<ILayoutProps> = ({
  children,
  pagePathName,
  pageTitle,
  pageDescription,
  pageImage,
  withFooter,
  withBottomSpacing,
  withHeader,
  section,
  siteNavigation,
  lang,
  footerSocialLabel,
  className,
}) => {
  return (
    <>
      <GlobalStyle />
      <Seo
        title={pageTitle}
        description={pageDescription}
        image={pageImage}
        lang={lang}
      />
      {withHeader && (
        <Header
          {...{ section, siteNavigation, pagePathName }}
          locale={lang as string}
        />
      )}
      <Main
        style={{ paddingBottom: withBottomSpacing ? '8rem' : '0' }}
        className={className}
      >
        {children}
      </Main>
      {withFooter && <Footer socialLabel={footerSocialLabel} />}
    </>
  );
};

Layout.defaultProps = {
  withFooter: true,
  withHeader: true,
};

const Main = styled.main`
  min-height: 100vh;
  background-color: ${colors.surface};
  padding-bottom: 8rem;
`;

export default Layout;
