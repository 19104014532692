import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';
import { borders, colors, fonts, mq } from '../styles/theme';

export const BaseContainer = styled.div`
  width: 100%;
  max-width: 1032px;
  margin: 0 auto;
  padding: 0 1rem;
  ${mq.desktop} {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const BaseBlogPostImage = styled(GatsbyImage)`
  ${borders.blogPostImageBorder};
  ${borders.radius};
`;

export const BaseBlogPostTitle = styled.h3`
  font-size: 22px;
  line-height: 22px;
  margin: 4px 0 7px;
`;

export const BaseBlogPostDescription = styled.p`
  margin: 10px 0 0;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.text.grey};
  font-weight: 400;
  font-family: ${fonts.serif};
`;

export const BaseSpacer = styled.div`
  ${borders.spacer};
  padding-bottom: 2rem;
  margin-bottom: 2rem;
`;

export const BaseRifAssetContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
