import * as React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Logo } from '../../icons';
import { fonts, mq } from '../../styles/theme';
import { SiteNavigationQuery } from '../../../gatsby-graphql';
import LocaleToggle from '../LocaleToggle';

interface IHeaderNavProps {
  siteNavigation: SiteNavigationQuery['contentfulNavigation'];
  locale: string;
  pagePathName: string;
}

const HeaderNav: React.FC<IHeaderNavProps> = ({
  siteNavigation,
  locale,
  pagePathName,
}) => {
  return (
    <StyledNav>
      <LinksContainer>
        <LogoLink to={`/${locale}`} title="Home">
          <StyledLogo />
        </LogoLink>
        {siteNavigation?.navigation?.map(
          (item) =>
            item && (
              <StyledLink
                key={String(item.link)}
                to={`/${locale}${item.link}`}
                title={String(item.name)}
                activeClassName="active"
                partiallyActive
              >
                {item.name}
              </StyledLink>
            )
        )}
      </LinksContainer>
      <RightContainer>
        <LocaleToggle {...{ locale, pagePathName }} />
      </RightContainer>
    </StyledNav>
  );
};

const LogoLink = styled(Link)`
  margin-right: 3rem;
  line-height: 0;
`;

const StyledLogo = styled(Logo)`
  height: 36px;
`;

const StyledNav = styled.nav`
  display: none;
  ${mq.tablet} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
  }
  ${mq.desktop} {
    padding: 1rem 0;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLink = styled(Link)`
  font-family: ${fonts.sansSerif};
  text-decoration: none;
  color: inherit;
  &.active {
    font-weight: bold;
    border-bottom: 1px solid;
  }
  &:not(:last-of-type) {
    margin-right: 1.5rem;
  }
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
`;

export default React.memo(HeaderNav);
