import { graphql, useStaticQuery } from 'gatsby';
import { PersonInfoQuery } from '../../../gatsby-graphql';

export const usePerson = () => {
  const data = useStaticQuery<PersonInfoQuery>(graphql`
    query PersonInfo {
      contentfulPerson {
        ...HomePagePersonInfo
      }
    }
  `);
  return data.contentfulPerson;
};
